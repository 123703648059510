import React from 'react';

// Define the types for the props
interface WhyButtonProps {
  id: string;
  nama: string;
  imageUrl: string;
  className?: string;
  callbackButton: (id: string) => void;
}

// Define the types for the state
interface WhyButtonState {
  id: string;
  nama: string;
}

class WhyButton extends React.Component<WhyButtonProps, WhyButtonState> {
  constructor(props: WhyButtonProps) {
    super(props);

    this.state = {
      id: props.id,
      nama: props.nama,
    };
  }

  handleButtonClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    event.preventDefault();
    this.props.callbackButton(this.state.id);
  };

  doRenderButton() {
    return (
      <div onClick={this.handleButtonClick} className={this.props.className} style={{ height: '100%', cursor: 'pointer' }}>
        <div>
          <img src={this.props.imageUrl} className="why-image-button" alt={this.state.nama} />
        </div>
        <div className="why-text-button">{this.state.nama}</div>
      </div>
    );
  }

  render() {
    return this.doRenderButton();
  }
}

export default WhyButton;
