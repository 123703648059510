import React from 'react';
import './style.css'

interface AppProps {
  reasons: JSX.Element[],
  featuredPics: string
}
class Why extends React.Component<AppProps> {
  doRenderReasonText() {
    var renderedReason = this.props.reasons.map(element => {
      return (
        <li className="why-list">
          {element}
        </li>
      )
    });
    return (
      <ul>
        {renderedReason}
      </ul>
    )
  }

  render() {
    return (
      <div className='why-div'>
        {this.doRenderReasonText()}
      </div>
    )
  }
}

export default Why;