import React, { Fragment } from 'react';
import NavBar from '../../Components/NavBar';
import { Helmet } from 'react-helmet'
import { Link } from 'react-router-dom';
import { Button } from 'react-bootstrap';

import './style.css'

class MobileAppsPage extends React.Component {
  doRenderNavbar() {
    var menuToShow = {
      "why": false,
      "contactPage": true,
      "contactSection": false,
      "home": false
    }
    return <NavBar disableTransition={true} menuToShow={menuToShow} />
  }
  doRenderHelmetProperties() {
    return (
      <Helmet>
        <title>Mobile Apps Development - Latensi</title>
        <meta name="description" content="We specialize in creating intuitive and powerful mobile applications for both iOS and Android platforms. Our expert team designs and develops custom mobile apps that not only meet your business needs but also provide an engaging user experience. From concept to deployment, Latensi ensures your mobile app is innovative, scalable, and reliable, helping you reach your audience effectively and drive business growth." />
      </Helmet>
    )
  }
  doRenderSection() {
    let email = 'https://mail.google.com/mail/?view=cm&fs=1&to=latensi.company@gmail.com';
    let fragment_story = [
      <Fragment><h3>Mobile Apps Development</h3></Fragment>,
      <Fragment><p>We specialize in creating intuitive and powerful mobile applications for both iOS and Android platforms. Our expert team designs and develops custom mobile apps that not only meet your business needs but also provide an engaging user experience. From concept to deployment, Latensi ensures your mobile app is innovative, scalable, and reliable, helping you reach your audience effectively and drive business growth.</p></Fragment>,
      <Fragment><div className='btn-konsultasi'><Link to={'/contact'} className="btn-konsultasi-link" >Reach us</Link></div></Fragment>
    ]
    return (
      <div>
        <div className='section-main'>
          <div className='section-1'>
            <div className='story'>{fragment_story}</div>
            <div className='img'></div>
          </div>
          <div className='section-2'>
            <div className='section-title'>
              <h3>For your software development needs</h3>
              <p>At Latensi, we pride ourselves on delivering top-notch software solutions that drive business success. Here are six compelling reasons why companies should partner with us for their software development projects:</p>
            </div>
            <div className='section-cards'>
              <div className='card'>
                <div className='expertise'></div>
                <div className='title'>Expertise and Experience</div>
                <div className='deskripsi'>With over a decade of experience in the software development industry, our team of skilled developers, designers, and engineers bring a wealth of knowledge and expertise to every project. We have successfully delivered numerous projects for more than 20 satisfied clients, demonstrating our capability to handle diverse and complex requirements.</div>
              </div>
              <div className='card'>
                <div className='client-centric'></div>
                <div className='title'>Client-Centric Approach</div>
                <div className='deskripsi'>We believe that the key to successful software development lies in understanding our clients' unique needs and objectives. At Latensi, we take a client-centric approach, working closely with you to ensure that our solutions are tailored to meet your specific business goals. Your success is our priority.</div>
              </div>
              <div className='card'>
                <div className='cutting-edge'></div>
                <div className='title'>Cutting-Edge Technology</div>
                <div className='deskripsi'>Staying ahead in the fast-paced world of technology is crucial. At Latensi, we leverage the latest tools, frameworks, and technologies to build innovative and robust software solutions. This ensures that our clients receive state-of-the-art products that are scalable, secure, and efficient.</div>
              </div>
              <div className='card'>
                <div className='quality-assurance'></div>
                <div className='title'>Quality Assurance</div>
                <div className='deskripsi'>Quality is at the heart of everything we do. Our rigorous quality assurance processes, including comprehensive testing and validation, ensure that our software solutions meet the highest standards of performance, reliability, and security. We are committed to delivering excellence in every project.</div>
              </div>
              <div className='card'>
                <div className='timely-delivery'></div>
                <div className='title'>Timely Delivery</div>
                <div className='deskripsi'>We understand the importance of meeting deadlines and staying within budget. Our efficient project management practices and agile development methodologies enable us to deliver projects on time without compromising on quality. When you choose Latensi, you can count on us for timely and reliable delivery.</div>
              </div>
              <div className='card'>
                <div className='support-maintenance'></div>
                <div className='title'>Ongoing Support and Maintenance</div>
                <div className='deskripsi'>Our relationship with our clients doesn't end with the delivery of the software. We offer continuous support and maintenance services to ensure that your software remains up-to-date and performs optimally. Whether it's adding new features, fixing bugs, or upgrading systems, Latensi is here to support your business every step of the way.

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
  doRenderFooter() {
    return (
      <div className='section-footer-service'>
        <div className='footer-card'>
          <div className='left'>
            <h2>Let's discuss about your need with our teams!</h2>
            <p>We provide a space for you to consult with us, feel free to contact us.</p>
          </div>
          <div className='right'>
            <Button className='btn-reach'><Link to={'/contact'} className="btn-konsultasi-link" >Reach us</Link></Button>
          </div>
        </div>
      </div>
    )
  }
  render() {
    return (
      <div>
        {this.doRenderHelmetProperties()}
        {this.doRenderNavbar()}
        {this.doRenderSection()}
        {this.doRenderFooter()}
      </div>
    )
  }
}

export default MobileAppsPage;