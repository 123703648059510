import React, { Fragment, RefObject } from 'react';
import { Carousel, Container, Row, Col } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import { Form, Button } from 'react-bootstrap';
import NavBar from '../Components/NavBar';
import emailjs from '@emailjs/browser';

import "./style.css"

interface ContactState {
  emailjs_gmail_service_id?: string,
  emailjs_gmail_template_id?: string,
  emailjs_public_key?: string,
  is_email_sent?: boolean,
  is_email_sent_failed?: boolean
}

class ContactPage extends React.Component<{}, ContactState> {

  private formRef: RefObject<HTMLFormElement>;
  constructor(props: {}) {
    super(props);
    this.state = {
      emailjs_gmail_service_id: 'service_x5d8fcb',
      emailjs_gmail_template_id: 'template_t3whfnx',
      emailjs_public_key: 'WIeIOQ7o0-UH5zNcP',
      is_email_sent: false,
      is_email_sent_failed: false
    }
    this.formRef = React.createRef();
  }

  doRenderNavbar() {
    var menuToShow = {
      "why": false,
      "contactPage": true,
      "contactSection": false,
      "home": false
    }
    return <NavBar disableTransition={true} menuToShow={menuToShow} />
  }
  doSendEmail = (e: any) => {
    e.preventDefault();
    console.log("clicked send email.")
    if (this.state.emailjs_gmail_service_id && this.state.emailjs_gmail_template_id && this.formRef.current && this.state.emailjs_public_key) {
      emailjs.sendForm(this.state.emailjs_gmail_service_id, this.state.emailjs_gmail_template_id, this.formRef.current, {
        publicKey: this.state.emailjs_public_key
      })
        .then(() => {
          console.log("Success")
          this.setState({
            is_email_sent: true
          })
        })
        .catch((error) => {
          console.log(error.message)
          this.setState({
            is_email_sent_failed: true
          })
        })
    }
  }
  doRenderContactTitle() {
    return (
      <div className='titles'>
        <h2>It's all starts here</h2>
        <p>Please complete the form below and we will contact you shortly.</p>
      </div>
    )
  }
  doRenderContactForm() {
    let form_html = <div></div>;
    let error_sent_html = <div></div>;
    if (this.state.is_email_sent) {
      form_html = (
        <div className='message-card'>
          <p>Your message has been sent. We will follow you up shortly!</p>
        </div>
      );
    }
    else if (this.state.is_email_sent_failed) {
      error_sent_html = <div>Failed to send a message. Try again later or contact our email directly : latensi.company@gmail.com</div>;
    }
    else {
      form_html = (
        <div className='message-card'>
          {error_sent_html}
          <Form ref={this.formRef} onSubmit={this.doSendEmail}>
            <Form.Group>
              <Form.Label>Name</Form.Label>
              <Form.Control type="text" name="user_name" placeholder='Full Name' />
            </Form.Group>
            <Form.Group>
              <Form.Label>Company</Form.Label>
              <Form.Control type="text" name="user_company" placeholder='Company Name' />
            </Form.Group>
            <Form.Group>
              <Form.Label>Phone Number</Form.Label>
              <Form.Control type="text" name="user_phone_number" placeholder='Phone Number' />
            </Form.Group>
            <Form.Group>
              <Form.Label>Message</Form.Label>
              <Form.Control as="textarea" name="user_message" placeholder='Give us a brief information about your case' rows={5} />
            </Form.Group>
            <Button type='submit' className='btn-submit'>Submit</Button>
          </Form>
        </div>
      );
    }
    return (
      <div className='section-main'>
        {this.doRenderContactTitle()}
        {form_html}
      </div>
    )
  }
  render() {
    console.log("Render state..")
    return (
      <div>
        {this.doRenderNavbar()}
        {this.doRenderContactForm()}
      </div>
    )
  }
}

export default ContactPage;