import React, { CSSProperties } from 'react';
import { Navbar, Nav, NavDropdown } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import '../Components/NavBar.css';

// Define the types for the props
interface MenuToShow {
  why?: boolean;
  contactSection?: boolean;
  contactPage?: boolean;
  home?: boolean;
}

interface NavBarProps {
  rgbTop?: string;
  menuToShow?: MenuToShow;
  disableTransition?: boolean;
  callbackScroll?: (section: string) => void;
}

// Define the types for the state
interface NavBarState {
  isTop: boolean;
  disableTransition?: boolean;
}

class NavBar extends React.Component<NavBarProps, NavBarState> {
  state: NavBarState = {
    isTop: true,
    disableTransition: this.props.disableTransition
  };

  componentDidMount() {
    document.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    document.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll = () => {
    const isTop = window.scrollY < 100;
    if (isTop !== this.state.isTop) {
      this.setState({ isTop });
    }
  };

  handleWhyClick = () => {
    if (this.props.callbackScroll) this.props.callbackScroll('why');
  };

  handleContactClick = () => {
    // if (this.props.callbackScroll) this.props.callbackScroll('contact');
  };

  doRenderNavbar() {
    let navbarClassName = '';
    let navbarStyle: CSSProperties = {};
    let navbarMenuClassName = '';
    let navbarLogoPath = '';

    // menus
    let navbarWhyMenu: JSX.Element | null = null;
    let navbarContactMenu: JSX.Element | null = null;

    if (this.state.disableTransition) {
      navbarStyle = {
        paddingTop: "0.2rem",
        paddingBottom: "0.2rem"
      };
      navbarClassName = "tal-navbar-white"
      navbarMenuClassName = "tal-navbar-text-offset"
      navbarLogoPath = "/assets/Universe/logo.png"
    }
    else {
      if (this.state.isTop) {
        navbarStyle = {
          backgroundColor: this.props.rgbTop,
          transition: 'all 2s ease',
          WebkitTransition: 'all 2s ease',
          MozTransition: 'all 2s ease',
          paddingTop: '0.2rem',
          paddingBottom: '0.2rem',
        };
        navbarMenuClassName = 'tal-navbar-text-white';
        navbarLogoPath = '/assets/Universe/white.png';
      } else {
        navbarStyle = {
          paddingTop: '0.2rem',
          paddingBottom: '0.2rem',
        };
        navbarClassName = 'tal-navbar-white';
        navbarMenuClassName = 'tal-navbar-text-offset';
        navbarLogoPath = '/assets/Universe/logo.png';
      }
    }

    // menus
    if (this.props.menuToShow) {
      if (this.props.menuToShow.why) {
        navbarWhyMenu = (
          <Nav.Link onClick={this.handleWhyClick}>
            <span className={navbarMenuClassName}>Why</span>
          </Nav.Link>
        );
      }
      if (this.props.menuToShow.contactSection) {
        // navbarContactMenu = (
        //   <Nav>
        //     <Nav.Link onClick={this.handleContactClick}>
        //       <span className={navbarMenuClassName}>Contact</span>
        //     </Nav.Link>
        //   </Nav>
        // );
        navbarContactMenu = (
          <Nav>
            <Nav.Link>
              <Link to={"/contact"} style={{ textDecoration: 'none' }}>
                <span className={navbarMenuClassName}>Contact</span>
              </Link>
            </Nav.Link>
          </Nav>
        );
      }
      if (this.props.menuToShow.contactPage) {
        // const email = 'https://mail.google.com/mail/?view=cm&fs=1&to=latensi.company@gmail.com';
        // navbarContactMenu = (
        //   <Nav>
        //     <Nav.Link href={email} target="_blank" rel="noopener noreferrer">
        //       <span className={navbarMenuClassName}>Contact</span>
        //     </Nav.Link>
        //   </Nav>
        // );
        navbarContactMenu = (
          <Nav>
            <Nav.Link>
              <Link to={"/contact"} style={{ textDecoration: 'none' }}>
                <span className={navbarMenuClassName}>Contact</span>
              </Link>
            </Nav.Link>
          </Nav>
        );
      }
    }

    return (
      <Navbar
        id="leap-navbar"
        collapseOnSelect
        expand="lg"
        fixed="top"
        style={navbarStyle}
        className={navbarClassName}
      >
        <Navbar.Brand href="/">
          <img
            alt="latensi"
            src={navbarLogoPath}
            className="tal-logo d-inline-block align-top"
          />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="mr-auto">
            {navbarWhyMenu}
            <NavDropdown
              title={<span className={navbarMenuClassName}>Services</span>}
              id="collasible-nav-dropdown"
            >
              <NavDropdown.Item>
                <Link to="/services/web">
                  Website Development
                </Link>
              </NavDropdown.Item>
              <NavDropdown.Item>
                <Link to="/services/mobile">
                  Mobile Apps Development
                </Link>
              </NavDropdown.Item>
              {/* <NavDropdown.Item>
                <Link to="/services/infra">
                  Software Infrastructure
                </Link>
              </NavDropdown.Item>
              <NavDropdown.Item>
                <Link to="/services/hardware-integration">
                  Hardware-Software Integration
                </Link>
              </NavDropdown.Item>
              <NavDropdown.Item>
                <Link to="/services/ai">
                  Artificial Intelligence
                </Link>
              </NavDropdown.Item> */}
              <NavDropdown.Item>
                <Link to="/services/uiux">
                  UI-UX Design
                </Link>
              </NavDropdown.Item>
              {/* <NavDropdown.Item>
                <Link to="/services/consultation">
                  Tech Consultation
                </Link>
              </NavDropdown.Item>
              <NavDropdown.Item>
                <Link to="/services/tech-support">
                  Dedicated Tech Support
                </Link>
              </NavDropdown.Item> */}
            </NavDropdown>
            <NavDropdown
              title={<span className={navbarMenuClassName}>Company</span>}
              id="collasible-nav-dropdown"
            >
              <NavDropdown.Item>
                <Link to="/about">
                  About us
                </Link>
              </NavDropdown.Item>
            </NavDropdown>
          </Nav>
          {navbarContactMenu}
        </Navbar.Collapse >
      </Navbar >
    );
  }

  render() {
    return <div>{this.doRenderNavbar()}</div>;
  }
}

export default NavBar;
