import React, { Fragment } from 'react';
import NavBar from '../Components/NavBar';
import { Helmet } from 'react-helmet'

import './about.css'

class AboutPage extends React.Component {
  doRenderSection() {
    let fragment_left = [
      <Fragment><h3>About Latensi</h3></Fragment>,
      <Fragment><p>We are a software development company that focus on developing software and tech solutions that can meet our client requirements</p></Fragment>,
      <Fragment><p>Kantor : Voza Tower (8th floor), Surabaya, Indonesia</p></Fragment>,
      <Fragment>
        <div className='ys'>
          <div className='ys-num'>2018</div>
          <div className='ys-txt'>When we start</div>
        </div></Fragment>
    ];
    let fragment_right = [
      <Fragment><h3>Mission</h3></Fragment>,
      <Fragment><p>Providing digital solutions and systems to help our clients tackle their problem.</p></Fragment>,
      // <Fragment><p className='tal-way'>Kebenaran |<br></br>
      //   Ketulusan | Loyalitas | Totalitas</p></Fragment>,
      // <Fragment><p>dilakukan dengan penuh :<br /> Antusias, Kerelaan, Tanggung dan Jawab</p></Fragment>
    ];
    let yearCopyright = `© latensi ${new Date().getFullYear()}`;
    return (
      <div>
        <div className="section-visi">
          {/* <div className="content-visi">
                    <h1 className="head-visi">Visi kami</h1>
                    <p className="p-visi">Memberikan manfaat melalui teknologi dan seni untuk mempermudah kehidupan manusia</p>
                </div> */}
          <div className='section-about'>
            <div className='section-fragment-left'>{fragment_left}</div>
            <div className='section-fragment-mid'></div>
            <div className='section-fragment-right'>{fragment_right}</div>
          </div>
          <div className='section-footer'>
            <div className='socmed'>
              <div className='ig'></div>
              <div className='yt'></div>
              <div className='in'></div>
              <div className='tw'></div>
            </div>
            <div className='copyright'>{yearCopyright}</div>
          </div>
        </div>

      </div>
    )
  }
  doRenderNavbar() {
    var menuToShow = {
      "why": false,
      "contactPage": true,
      "contactSection": false,
      "home": false
    }
    return <NavBar disableTransition={true} menuToShow={menuToShow} />
  }
  doRenderHelmetProperties() {
    return (
      <Helmet>
        <title>About us - Latensi</title>
        <meta name="description" content="We are a software development company that focus on developing software and tech solutions that can meet our client requirements." />
      </Helmet>
    )
  }

  render() {
    return (
      <div>
        {this.doRenderHelmetProperties()}
        {this.doRenderNavbar()}
        {this.doRenderSection()}
      </div>
    )
  }
}

export default AboutPage;