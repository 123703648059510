import React from 'react';

import './style.css'

interface Properties {
  company_bg_color?: string;
  company_text_color?: string;
}

interface IconStyleCustom {
  backgroundImage: string,
  backgroundRepeat: string,
  backgroundSize: string,
  backgroundPosition: string,
  marginTop: string,
  height: string,
  width: string
}

interface TextStyleCustom {
  color?: string;
  fontSize?: string;
}

interface PropCustom {
  icon_style?: IconStyleCustom;
  text?: string;
  text_style?: TextStyleCustom;
}

interface CekLayanan {
  web: string;
}
interface AppProps {
  url?: string;
  head?: string;
  subhead?: string;
  company_name?: string;
  properties?: Properties;
  prop_android?: boolean;
  prop_ios?: boolean;
  prop_custom?: PropCustom[];
  prop_year_made?: number | string;
  prop_access?: string;
  prop_cek_layanan?: boolean;
  cek_layanan?: CekLayanan;
}

interface AppState { }

class FeaturedPorto extends React.Component<AppProps, AppState> {
  doRenderPorto() {
    if (this.props.url) {
      return (
        <div>
          <img
            alt={this.props.head}
            src={this.props.url}
            className="featured-image"
          />
        </div>
      )
    }
    else return <div></div>
  }
  doRenderHeadingAndSubheading() {
    if (this.props.head) {
      var subHeadHTML = <div></div>, companyNameHTML = <div></div>, projectPropertiesHTML = <div></div>, cekLayananHTML = <div></div>;
      var propAndroid = <div style={{ display: 'inline-block' }}></div>, propIOS = <div style={{ display: 'inline-block' }}></div>, propYearMade = <div style={{ display: 'inline-block' }}></div>, propAccess = <div style={{ display: 'inline-block' }}></div>, propCustom = <div style={{ display: 'inline-block' }}></div>;
      if (this.props.subhead) {
        subHeadHTML = <div className="featured-content">{this.props.subhead}</div>
      }
      if (this.props.company_name) {
        var company_bg_color, company_text_color;
        if (this.props.properties) {
          company_bg_color = this.props.properties.company_bg_color;
          company_text_color = this.props.properties.company_text_color;
        }
        else {
          company_bg_color = '#FFDC00';
          company_text_color = '#FFFFFF';
        }
        companyNameHTML = <div style={{ padding: '2px 12px 2px 12px', borderRadius: '10px', background: company_bg_color, marginLeft: 'auto', marginRight: 'auto', display: 'inline-block' }} className="company-class">
          <div style={{ color: company_text_color }}>{this.props.company_name}</div>
        </div>
      }

      if (this.props.prop_android === true) {
        propAndroid = <div className='prop-android'>
          <div className='android'></div>
          <div className='text'>Android Support</div>
        </div>
      }
      if (this.props.prop_ios === true) {
        propIOS = <div className='prop-ios'>
          <div className='ios'></div>
          <div className='text'>IOS Support</div>
        </div>
      }
      if (this.props.prop_custom) {
        propCustom = (<>
          {
            this.props.prop_custom.map(element => {
              return (
                <div style={{ display: 'inline-block', width: '120px' }}>
                  <div style={element.icon_style}></div>
                  <div style={element.text_style}>{element.text}</div>
                </div>
              );
            })
          }
        </>)
      }
      if (this.props.prop_year_made) {
        propYearMade = <div className='prop-year-made'>
          <h2 className='year'>{this.props.prop_year_made}</h2>
          <div className='text'>Tahun Operasi</div>
        </div>
      }
      if (this.props.prop_access) {
        if (this.props.prop_access == "website") {
          propAccess = <div className='prop-access'>
            <div className='website'></div>
            <div className='text'>Akses Website</div>
          </div>
        }
      }
      projectPropertiesHTML = <div className='project-properties'>
        {propAndroid}
        {propIOS}
        {propCustom}
        {propYearMade}
        {propAccess}
      </div>

      if (this.props.prop_cek_layanan) {
        var a_link = "#";
        if (this.props.cek_layanan) {
          a_link = this.props.cek_layanan.web;
        }
        cekLayananHTML = <div className='cek-layanan'>
          <a className='text' href={a_link} target="_blank">
            <span style={{ display: 'inline-block' }}>
              Cek Layanan
            </span>
          </a>
        </div>
      }

      var flexProjectPropertiesHTML = (
        <div>
          <div className='flex-c-row-center'>
            <div style={{ width: '30%' }}></div>
            {projectPropertiesHTML}
            <div style={{ width: '30%' }}>{cekLayananHTML}</div>
          </div>
          <div className='flex-c-col-mobile'>
            <div className='flex-c-row-mobile'>
              {projectPropertiesHTML}
            </div>
            {cekLayananHTML}
          </div>
        </div>

      )
      return (
        <div className='flex-c-col-center'>
          <h1 className="heading">{this.props.head}</h1>
          {subHeadHTML}
          {companyNameHTML}
          {flexProjectPropertiesHTML}
        </div>
      )
    }
    else {
      return <div></div>
    }
  }
  render() {
    return (
      <div>
        {this.doRenderPorto()}
        {this.doRenderHeadingAndSubheading()}
      </div>
    )
  }
}

export default FeaturedPorto;