import React, { Fragment, RefObject } from 'react';
import { Carousel, Container, Row, Col } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import NavBar from '../Components/NavBar';
import FeaturedPorto from './Featured/FeaturedPorto';
import Why from './Why/Why';
import WhyButton from './Why/WhyButton';
import Service from './Service/Service';
import Customer from './Customer/Customer';
import Alur from './Alur/Alur'
import { Link } from 'react-router-dom';

import "./style.css";

interface MainState {
  index: number;
  direction: string | null;
  whyId: string;
  showWhatsapp: boolean;
  opacityWhatsapp: number;
}

class Main extends React.Component<{}, MainState> {
  private whyRefs: RefObject<HTMLDivElement>;
  private contactRefs: RefObject<HTMLDivElement>;
  private serviceRefs: RefObject<HTMLDivElement>;
  private customerRefs: RefObject<HTMLDivElement>;

  constructor(props: {}) {
    super(props);

    this.state = {
      index: 0,
      direction: null,
      whyId: "1",
      showWhatsapp: false,
      opacityWhatsapp: 0
    }
    this.whyRefs = React.createRef();
    this.contactRefs = React.createRef();
    this.serviceRefs = React.createRef();
    this.customerRefs = React.createRef();
  }
  handleWhyButtonClick = (id: string) => {
    this.setState({
      whyId: id
    })
  }

  handleSelect = (selectedIndex: number, e: any) => {
    this.setState({ index: selectedIndex });
  }
  handleWhatsappButtonClick = () => {
    this.setState(prevState => ({ showWhatsapp: !prevState.showWhatsapp }));
  }

  handleScrollTo = (section: string) => {
    const navbarhtml = document.getElementById("leap-navbar");
    let scrollTop = window.pageYOffset || document.documentElement.scrollTop;

    if (section === "why") {
      const whyhtml = document.getElementById("section-why");
      if (whyhtml && navbarhtml) {
        const whyDomRect = whyhtml.getBoundingClientRect();
        scrollTop += whyDomRect.top - navbarhtml.offsetHeight;
      }
    }
    window.scrollTo(0, scrollTop);
  }
  doRenderNavbar() {
    var menuToShow = {
      "why": true,
      "contactPage": false,
      "contactSection": true,
      "home": false
    }
    var navbar_color = "";
    if (this.state.index == 0) navbar_color = "rgba(15, 102, 102, 0.5)";
    if (this.state.index == 1) navbar_color = "rgba(206, 130, 130, 0.4)";
    if (this.state.index == 2) navbar_color = "rgba(130, 133, 206, 0.4)";
    if (this.state.index == 3) navbar_color = "rgba(23, 9, 72, 0.55)";
    if (this.state.index == 4) navbar_color = "rgba(178, 97, 1, 0.55)";
    if (this.state.index == 5) navbar_color = "rgba(0, 70, 107, 0.55)";
    if (this.state.index == 6) navbar_color = "rgba(4, 186, 0, 0.55)";
    if (this.state.index == 7) navbar_color = "rgba(23, 9, 72, 0.5)";
    if (this.state.index == 8) navbar_color = "rgba(58, 58, 58, 0.5)";
    if (this.state.index == 9) navbar_color = "rgba(29, 88, 135, 0.5)";
    if (this.state.index == 10) navbar_color = "rgba(177, 31, 0, 0.6)";
    if (this.state.index == 11) navbar_color = "rgba(170, 5, 0, 0.5)";
    if (this.state.index == 12) navbar_color = "rgba(178, 97, 1, 0.6)";

    return <NavBar callbackScroll={this.handleScrollTo} menuToShow={menuToShow} rgbTop={navbar_color} />
  }
  doRenderHelmetProperties() {
    return (
      <Helmet>
        <title>Latensi - Software Development Company</title>
        <meta name="description" content="Latensi is a leading software development company specializing in custom software, web, and mobile app development. With over a decade of experience and more than 30 satisfied clients, we offer innovative solutions, UI/UX design, and comprehensive maintenance services to ensure your digital products are cutting-edge and reliable." />
      </Helmet>
    )
  }
  doRenderFeaturedPorto() {
    var init_data = [
      {
        img_url: "/assets/Home/Porto/p_12_beraucoal.png",
        head: "JOBCENTER BERAUCOAL",
        subhead: "Talent pool and training management platform.",
        company_name: "PT. Berau Coal",
        prop_android: false,
        prop_ios: false,
        prop_year_made: "2024",
        prop_access: "website",
        properties: {
          company_bg_color: "#FFDC00",
          company_text_color: "#00464E"
        },
        prop_cek_layanan: false
      },
      {
        img_url: "/assets/Home/Porto/p_13_paperpen.png",
        head: "PAPERPEN",
        subhead: "Stationary e-commerce platform.",
        company_name: "CV. Sinar Karunia Sulung",
        prop_android: true,
        prop_ios: true,
        prop_year_made: "2024",
        prop_access: "website",
        properties: {
          company_bg_color: "#FFDC00",
          company_text_color: "#00464E"
        },
        prop_cek_layanan: false
      },
      {
        img_url: "/assets/Home/Porto/p_14_ahs.png",
        head: "ANALISAHARGASATUAN",
        subhead: "Construction project procurement platform",
        company_name: "CV. Cremona Teknologi",
        prop_android: false,
        prop_ios: false,
        prop_year_made: "2024",
        prop_access: "website",
        properties: {
          company_bg_color: "#9276AC",
          company_text_color: "#FFFFFF"
        },
        prop_cek_layanan: true,
        cek_layanan: {
          web: "https://analisahargasatuan.com"
        }
      },
      {
        img_url: "/assets/Home/Porto/p_7_proyekid.png",
        head: "PROYEKID.COM",
        subhead: "Construction monitoring and management system",
        company_name: "PT. Akkarya Jaya Pratama",
        prop_android: true,
        prop_ios: true,
        prop_year_made: "2023",
        prop_access: "website",
        properties: {
          company_bg_color: "#9276AC",
          company_text_color: "#FFFFFF"
        },
        prop_cek_layanan: true,
        cek_layanan: {
          web: "https://proyekid.com"
        }
      },
      {
        img_url: "/assets/Home/Porto/p_3_idaku.png",
        head: "IDAKU.ID",
        subhead: "Warehouse and distribution management system",
        company_name: "PT. Indotama Seraya Artha",
        prop_android: false,
        prop_ios: false,
        prop_year_made: "2021",
        prop_access: "website",
        properties: {
          company_bg_color: "#CD773B",
          company_text_color: "#FFFFFF"
        },
        prop_cek_layanan: false
      },
      {
        img_url: "/assets/Home/Porto/p_4_kiansgemlab.png",
        head: "KIANSGEMLAB.COM",
        subhead: "Gem certification and company profile",
        company_name: "Kians Gemologycal Laboratory",
        prop_android: false,
        prop_ios: false,
        prop_year_made: "2022",
        prop_access: "website",
        properties: {
          company_bg_color: "#4A6155",
          company_text_color: "#FFFFFF"
        },
        prop_cek_layanan: false
      },
      {
        img_url: "/assets/Home/Porto/p_5_sinarmart.png",
        head: "SINAR",
        subhead: "Mini ERP",
        company_name: "CV. Sinar Karunia Sulung",
        prop_android: false,
        prop_ios: false,
        prop_year_made: "2020",
        prop_access: "website",
        properties: {
          company_bg_color: "#4A6155",
          company_text_color: "#FFFFFF"
        },
        prop_cek_layanan: false,
        prop_custom: [
          {
            text: "Local Service",
            icon_style: {
              backgroundImage: `url('/assets/Universe/Props/local.png')`,
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'auto 24px',
              backgroundPosition: 'center',
              marginTop: '2px',
              height: '30px',
              width: 'auto'
            },
            text_style: {
              color: "white",
              fontSize: "small"
            }
          }
        ]
      },
      {
        img_url: "/assets/Home/Porto/p_6_rajapaksi.png",
        head: "RAJAPAKSI",
        subhead: "Sales officer monitoring system",
        company_name: "PT. Rajapaksi Adyaperkasa",
        prop_android: true,
        prop_ios: false,
        prop_year_made: "2020",
        prop_access: "website",
        properties: {
          company_bg_color: "#04BA00",
          company_text_color: "#FFFFFF"
        },
        prop_cek_layanan: false,
        prop_custom: [
          {
            text: "Barcode Scanner",
            icon_style: {
              backgroundImage: `url('/assets/Universe/Props/barcode.png')`,
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'auto 24px',
              backgroundPosition: 'center',
              marginTop: '2px',
              height: '30px',
              width: 'auto'
            },
            text_style: {
              color: "white",
              fontSize: "small"
            }
          }
        ]
      },
      {
        img_url: "/assets/Home/Porto/p_8_hungerdash.png",
        head: "HUNGERDASH.COM",
        subhead: "Online food delivery",
        company_name: "CV. Teka Tama",
        prop_android: true,
        prop_ios: true,
        prop_year_made: "2021",
        prop_access: "website",
        properties: {
          company_bg_color: "#FFDC00",
          company_text_color: "#00464E"
        },
        prop_cek_layanan: false,
        cek_layanan: {
          web: "https://hungerdash.com"
        }
      },
      {
        img_url: "/assets/Home/Porto/p_9_haleyora.png",
        head: "QMETER LISTRIQU",
        subhead: "Software for logging token changing for customer of PT. PLN (Persero) - Jawa Timur, Bali, DKI Jakarta and Sulawesi",
        company_name: "PT. PLN (Persero)",
        prop_android: true,
        prop_ios: false,
        prop_year_made: "2019",
        prop_access: "website",
        properties: {
          company_bg_color: "#FFA900",
          company_text_color: "#FFFFFF"
        },
        prop_cek_layanan: false
      },
      {
        img_url: "/assets/Home/Porto/p_10_mrsumo.png",
        head: "MR SUMO - POS",
        subhead: "Restaurant management system",
        company_name: "Mr. Sumo - Resto All You Can Eat",
        prop_android: false,
        prop_ios: false,
        prop_year_made: "2022",
        prop_access: "website",
        properties: {
          company_bg_color: "#F20B0B",
          company_text_color: "#FFFFFF"
        },
        prop_cek_layanan: false,
        prop_custom: [
          {
            icon_style: {
              backgroundImage: `url('/assets/Universe/Props/gate.png')`,
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'auto 24px',
              backgroundPosition: 'center',
              marginTop: '2px',
              height: '30px',
              width: 'auto'
            },
            text: "Gate System",
            text_style: {
              color: "white",
              fontSize: "small"
            }
          },
          {
            icon_style: {
              backgroundImage: `url('/assets/Universe/Props/print.png')`,
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'auto 24px',
              backgroundPosition: 'center',
              marginTop: '2px',
              height: '30px',
              width: 'auto'
            },
            text: "Cetak Nota",
            text_style: {
              color: "white",
              fontSize: "small"
            }
          }
        ]
      },
      {
        img_url: "/assets/Home/Porto/p_11_citraniaga.png",
        head: "CITRA NIAGA LOGISTIK",
        subhead: "Logistic management system",
        company_name: "PT. Citra Niaga Logistik",
        prop_android: false,
        prop_ios: false,
        prop_year_made: "2018",
        prop_access: "website",
        properties: {
          company_bg_color: "#AA0500",
          company_text_color: "#FFFFFF"
        },
        prop_cek_layanan: false,

      },

      {
        img_url: "/assets/Home/Porto/p_1_toekoemart.png",
        head: "TOEKOEMART",
        subhead: "Daily needs online shopping",
        company_name: "PT. Toekoe Indonesia Persada",
        prop_android: true,
        prop_ios: true,
        prop_year_made: "2020",
        prop_access: "website",
        properties: {
          company_bg_color: "#FFDC00",
          company_text_color: "#00464E"
        },
        prop_cek_layanan: false,
        cek_layanan: {
          web: "https://www.toekoemart.com"
        }
      },
      // {
      //   img_url: "/assets/Home/Porto/p_2_kursusnikah.png",
      //   head: "KURSUSNIKAH",
      //   subhead: "Online course of pre-wedding and marriage",
      //   company_name: "PT. Namary Insan Solusi",
      //   prop_android: false,
      //   prop_ios: false,
      //   prop_year_made: "2020",
      //   prop_access: "website",
      //   properties: {
      //     company_bg_color: "#CE8282",
      //     company_text_color: "#FFFFFF"
      //   },
      //   prop_cek_layanan: false,
      //   cek_layanan: {
      //     web: "https://kursusnikah.com"
      //   }

      // },
    ]

    var carousel_items = init_data.map((element, index) => {
      return (
        <Carousel.Item key={index}>
          <FeaturedPorto url={element.img_url} head={element.head} subhead={element.subhead} company_name={element.company_name} prop_android={element.prop_android} prop_ios={element.prop_ios} prop_year_made={element.prop_year_made} prop_access={element.prop_access} properties={element.properties} prop_cek_layanan={element.prop_cek_layanan} cek_layanan={element.cek_layanan} prop_custom={element.prop_custom} />
        </Carousel.Item>
      )
    })

    return (
      <Carousel activeIndex={this.state.index} onSelect={this.handleSelect} style={{ minHeight: "100vh", paddingTop: "18vh" }} >
        {carousel_items}
      </Carousel>
    )
  }
  doRenderHome() {
    var carouselBg = {
      backgroundImage: "none",
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "fixed",
      height: "100vh"
    }
    if (this.state.index >= 0) {
      switch (this.state.index) {
        case 0:
          carouselBg.backgroundImage = "linear-gradient(rgba(0,0,0,.25), rgba(0,0,0,.25)), url('/assets/Home/Porto/c_12_beraucoal.jpg')";
          break;
        case 1:
          carouselBg.backgroundImage = "linear-gradient(rgba(206, 130, 130, 0.2), rgba(206, 130, 130, 0.2)), url('/assets/Home/Porto/c_13_paperpen.jpg')";
          break;
        case 2:
          carouselBg.backgroundImage = "linear-gradient(rgba(146, 118, 172, 0.25), rgba(146, 118, 172, 0.25)), url('/assets/Home/Porto/c_14_ahs.jpg')";
          break;
        case 3:
          carouselBg.backgroundImage = "linear-gradient(rgba(146, 118, 172, 0.25), rgba(146, 118, 172, 0.25)), url('/assets/Home/Porto/c_7_proyekid.jpeg')";
          break;
        case 4:
          carouselBg.backgroundImage = "linear-gradient(rgba(178, 97, 1, 0.2), rgba(178, 97, 1, 0.2)), url('/assets/Home/Porto/c_3_idaku.jpeg')";
          break;
        case 5:
          carouselBg.backgroundImage = "linear-gradient(rgba(74, 97, 85, 0.2), rgba(74, 97, 85, 0.2)), url('/assets/Home/Porto/c_4_kiansgemlab.jpeg')";
          break;
        case 6:
          carouselBg.backgroundImage = "linear-gradient(rgba(0, 70, 107, 0.3), rgba(0, 70, 107, 0.3)), url('/assets/Home/Porto/c_5_sinarmart.jpeg')";
          break;
        case 7:
          carouselBg.backgroundImage = "linear-gradient(rgba(2, 107, 0, 0.3), rgba(2, 107, 0, 0.3)), url('/assets/Home/Porto/c_6_rajapaksi.jpeg')";
          break;

        case 8:
          carouselBg.backgroundImage = "linear-gradient(rgba(0,0,0,0.4), rgba(0,0,0,0.4)), url('/assets/Home/Porto/c_8_hungerdash.jpeg')";
          break;
        case 9:
          carouselBg.backgroundImage = "linear-gradient(rgba(23, 9, 72, 0.25), rgba(23, 9, 72, 0.25)), url('/assets/Home/Porto/c_9_haleyora.jpeg')";
          break;
        case 10:
          carouselBg.backgroundImage = "linear-gradient(rgba(0, 0, 0, 0.4), rgba(0,0,0,0.4)), url('/assets/Home/Porto/c_10_mrsumo.png')";
          break;
        case 11:
          carouselBg.backgroundImage = "linear-gradient(rgba(0,0,0,0.4), rgba(0,0,0,0.4)), url('/assets/Home/Porto/c_11_citraniaga.jpeg')";
          break;
        case 12:
          carouselBg.backgroundImage = "linear-gradient(rgba(0,0,0,.25), rgba(0,0,0,.25)), url('/assets/Home/Porto/c_1_toekoemart.jpeg')";
          break;
      }
      /*
      if (this.state.index == 0) {
        carouselBg.backgroundImage = "linear-gradient(rgba(0,0,0,.25), rgba(0,0,0,.25)), url('/assets/Home/Porto/c_1_toekoemart.jpeg')"
      }
      // if (this.state.index == 1) {
      //     carouselBg.backgroundImage = "linear-gradient(rgba(0,0,0,.5), rgba(0,0,0,.5)), url('/assets/Home/Porto/c_2_kollabora.jpg')"
      // }
      if (this.state.index == 1) {
        carouselBg.backgroundImage = "linear-gradient(rgba(206, 130, 130, 0.2), rgba(206, 130, 130, 0.2)), url('/assets/Home/Porto/c_2_kursusnikah.jpeg')"
      }
      if (this.state.index == 2) {
        carouselBg.backgroundImage = "linear-gradient(rgba(178, 97, 1, 0.2), rgba(178, 97, 1, 0.2)), url('/assets/Home/Porto/c_3_idaku.jpeg')"
      }
      if (this.state.index == 3) {
        carouselBg.backgroundImage = "linear-gradient(rgba(74, 97, 85, 0.2), rgba(74, 97, 85, 0.2)), url('/assets/Home/Porto/c_4_kiansgemlab.jpeg')"
      }

      if (this.state.index == 4) {
        carouselBg.backgroundImage = "linear-gradient(rgba(0, 70, 107, 0.3), rgba(0, 70, 107, 0.3)), url('/assets/Home/Porto/c_5_sinarmart.jpeg')"
      }
      if (this.state.index == 5) {
        carouselBg.backgroundImage = "linear-gradient(rgba(2, 107, 0, 0.3), rgba(2, 107, 0, 0.3)), url('/assets/Home/Porto/c_6_rajapaksi.jpeg')"
      } if (this.state.index == 6) {
        carouselBg.backgroundImage = "linear-gradient(rgba(146, 118, 172, 0.25), rgba(146, 118, 172, 0.25)), url('/assets/Home/Porto/c_7_proyekid.jpeg')"
      } if (this.state.index == 7) {
        carouselBg.backgroundImage = "linear-gradient(rgba(0,0,0,0.4), rgba(0,0,0,0.4)), url('/assets/Home/Porto/c_8_hungerdash.jpeg')"
      } if (this.state.index == 8) {
        carouselBg.backgroundImage = "linear-gradient(rgba(23, 9, 72, 0.25), rgba(23, 9, 72, 0.25)), url('/assets/Home/Porto/c_9_haleyora.jpeg')"
      } if (this.state.index == 9) {
        carouselBg.backgroundImage = "linear-gradient(rgba(0, 0, 0, 0.4), rgba(0,0,0,0.4)), url('/assets/Home/Porto/c_10_mrsumo.png')"
      } if (this.state.index == 10) {
        carouselBg.backgroundImage = "linear-gradient(rgba(0,0,0,0.4), rgba(0,0,0,0.4)), url('/assets/Home/Porto/c_11_citraniaga.jpeg')"
      } */
    }

    //console.log(carouselBg)
    return (
      <div>
        <div style={carouselBg}>
          {this.doRenderFeaturedPorto()}
        </div>
        <div className="section-why-bg">
          {this.doRenderWhy()}
        </div>
        <div className='section-service-bg'>
          {this.doRenderService()}
        </div>
        <div className='section-customer-bg'>
          {this.doRenderCustomer()}
        </div>
        <div className="section-alur-bg">
          {this.doRenderAlur()}
        </div>
      </div>
    )
  }
  doRenderWhy() {
    var whyOneList = [
      <Fragment><span style={{ fontWeight: 'bold' }}>Customer focused.</span></Fragment>,
      <Fragment><span style={{ fontWeight: 'bold' }}>We are focusing to understand the customer and </span>give solutions that fits for your study case.</Fragment>,
      <Fragment><span style={{ fontWeight: 'bold' }} >All of our development phases focus on </span>the needs of your study case.</Fragment>
    ]
    var whyTwoList = [
      < Fragment > <span style={{ fontWeight: 'bold' }}>Experienced</span> - Our team has completed projects for more than 30 clients with varying levels of complexity</Fragment >,
      <Fragment><span style={{ fontWeight: 'bold' }}>Qualified</span> - Supported by highly competent and qualified human resources in their respective fields.</Fragment>,
      <Fragment><span style={{ fontWeight: 'bold' }}>Adaptive</span> - We continuously innovate using the latest technologies to stay relevant with the advancements in the tech world.</Fragment>,
    ]
    //console.log("Why terpilih", this.state.whyId)

    var whyLayout, whyImage;
    if (this.state.whyId == "1") {
      whyLayout = <Why reasons={whyOneList} featuredPics="" />
      whyImage = <img src="/assets/Home/Why/w_featured_apps.png" className='why-image' />
    }
    if (this.state.whyId == "2") {
      whyLayout = <Why reasons={whyTwoList} featuredPics="" />
      whyImage = <img src="/assets/Home/Why/w_qualified_apps.png" className='why-image' />
    }

    return (
      <div ref={this.whyRefs} id="section-why">
        <div className='section-why-on-desktop'>
          <div>
            <Container>
              <Row>
                <Col xs md="3">
                  <h1 className="section-why-title">Why us?</h1>
                </Col>
                <Col xs md="4">
                  {whyImage}
                </Col>
                <Col xs md="5">
                  {whyLayout}
                </Col>
              </Row>
            </Container>
          </div>
          <div className="section-why-button-group">
            <Container>
              <Row className="justify-content-md-center">
                <Col xs md="2">
                  <WhyButton id="1" imageUrl="/assets/Universe/w_featured.png" className="section-why-button" nama="Customer Focused" callbackButton={this.handleWhyButtonClick} />
                </Col>
                <Col xs md="2">
                  <WhyButton id="2" imageUrl="/assets/Universe/w_qualified.png" className="section-why-button" nama="Experienced" callbackButton={this.handleWhyButtonClick} />
                </Col>
                {/* <Col xs md="2">
                  <WhyButton id="3" imageUrl='' className='section-why-button' nama="Solutif" callbackButton={this.handleWhyButtonClick} />
                </Col> */}
              </Row>
            </Container>
          </div>
        </div>
        <div className='section-why-on-mobile'>
          <Container>
            <h1 className="section-why-title">Why us?</h1>
            <div className='why-image-center'>{whyImage}</div>
            {whyLayout}
            <div className="section-why-button-group">
              <Container>
                <Row className="justify-content-md-center">
                  <Col xs md="2">
                    <WhyButton id="1" imageUrl="/assets/Universe/w_featured.png" className="section-why-button" nama="Customer Focused" callbackButton={this.handleWhyButtonClick} />
                  </Col>
                  <Col xs md="2">
                    <WhyButton id="2" imageUrl="/assets/Universe/w_qualified.png" className="section-why-button" nama="Experienced" callbackButton={this.handleWhyButtonClick} />
                  </Col>
                </Row>
              </Container>
            </div>
          </Container>

        </div>
      </div>
    )
  }
  doRenderAlur() {
    var email = `https://mail.google.com/mail/?view=cm&fs=1&to=latensi.company@gmail.com`

    var button_whatsapp;
    if (this.state.showWhatsapp) {
      button_whatsapp = (
        <button className='btn btn-whatsapp-clicked' onClick={this.handleWhatsappButtonClick}>
          <div className='wa-icon-flex'>
            <div className='wa-icon'></div><div className='wa-icon-text'>Reach us</div>
          </div>
        </button>
      );
    }
    else {
      button_whatsapp = (
        <button className='btn btn-whatsapp' onClick={this.handleWhatsappButtonClick}>
          <div className='wa-icon-flex'>
            <div className='wa-icon'></div><div className='wa-icon-text'>Reach us</div>
          </div>
        </button>
      )
    }
    return (
      <Container ref={this.contactRefs}>
        {/* <h1>Mempersiapkan anda ke dunia 4.0</h1> */}
        <Alur />
        <div className="section-contact-button-list">
          <Link className='btn btn-light' to={'/contact'} >Reach us</Link>
          {/* {button_whatsapp} */}
        </div>
        {this.doRenderWhatsappModal()}
      </Container>
    )
  }
  doRenderService() {
    return (
      <Container ref={this.serviceRefs}>
        <Service />
      </Container>
    )
  }
  doRenderCustomer() {
    return (
      <Container ref={this.customerRefs}>
        <Customer />
      </Container>
    );
  }
  openWhatsapp = (phone_number: string, nickname: string) => {
    window.open(`https://wa.me/${phone_number}?text=${encodeURIComponent(`Hi kak ${nickname}, saya ingin berkonsultasi mengenai pembuatan software di latensi`)}`)
  }
  doRenderWhatsappModal() {
    var return_whatsapp = <div></div>
    if (this.state.showWhatsapp) {
      return_whatsapp = (
        <div className='section-whatsapp' >
          <div className='profile-container' style={{ transition: 'opacity 500ms ease-in-out', opacity: `${this.state.opacityWhatsapp}` }} onClick={() => { this.openWhatsapp("6281330210424", "Andre") }}>
            <div style={{ flexGrow: "1", display: 'flex', alignItems: 'center' }}>
              <img style={{ width: '40px', height: '40px', display: 'inline-block' }} src='/assets/Contact/febri2.png'></img>
              <div style={{ display: 'inline-block', paddingLeft: '4px' }}>Customer Care 1</div>
            </div>
            <div style={{ flexBasis: "100px", display: 'flex', alignItems: 'center', justifyContent: 'right' }}>
              <div>
                Hubungi
              </div>

            </div>
          </div>
          <div className='profile-container' style={{ transition: 'opacity 500ms ease-in-out', opacity: `${this.state.opacityWhatsapp}` }} onClick={() => { this.openWhatsapp("6285648395501", "Romario") }}>

            <div style={{ flexGrow: "1", display: 'flex', alignItems: 'center' }}>
              <img style={{ width: '40px', height: '40px', display: 'inline-block' }} src='/assets/Contact/romario2.png'></img>
              <div style={{ display: 'inline-block', paddingLeft: '4px' }}>Customer Care 2</div>
            </div>
            <div style={{ flexBasis: "100px", display: 'flex', alignItems: 'center', justifyContent: 'right' }}>
              <div>
                Hubungi
              </div>

            </div>
          </div>
        </div>
      )
    }
    return return_whatsapp;
  }

  render() {
    return (
      <div >
        {this.doRenderHelmetProperties()}
        {this.doRenderNavbar()}
        {this.doRenderHome()}
      </div>
    )
  }
}

export default Main;