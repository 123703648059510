import React, { Fragment } from 'react';
import "./style.css"

class Alur extends React.Component {
  doRenderAlur() {
    let headerFragment = [
      <Fragment>Our development flow</Fragment>
    ]
    return (
      <div>
        <div>
          <h1 className='alur-h1'>{headerFragment}</h1>
          <p>that how we serve our customer</p>
        </div>
        <div className='flex-alur'>
          <div className='alur-image'>
            <div className='image'></div>
          </div>
        </div>
      </div>
    );
  }
  render() {
    return (
      <div>
        {this.doRenderAlur()}
      </div>
    )
  }
}

export default Alur;