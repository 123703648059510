import React from 'react';
import './style.css';

class Customer extends React.Component {
  doRenderCustomerList() {
    return (
      <div className='section-text'>
        <h1>Some of our clients</h1>
        <p>that used our service to develop their system</p>
      </div>
    );
  }
  doRenderCustomerImage() {
    return (
      <div className='customer'></div>
    );
  }
  render() {
    return (
      <div>
        {this.doRenderCustomerList()}
        {this.doRenderCustomerImage()}
      </div>
    )
  }
}

export default Customer;