import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';

import HomePage from "./Home/Main";
import AboutPage from './Company/About';
import WebsitePage from './Services/Website/Website';
import MobileAppsPage from './Services/MobileApps/MobileApps';
import MaintenancePage from './Services/Maintenance/Maintenance';
import UIUXPage from './Services/UIUX/UIUX';
import ContactPage from './Contact/Contact';

class App extends React.Component {
  render() {
    return (
      <Router>
        <Routes>
          <Route path={'/'} element={<HomePage />} />
          <Route path={'/about'} element={<AboutPage />} />
          <Route path={'/services/web'} element={<WebsitePage />} />
          <Route path={'/services/mobile'} element={<MobileAppsPage />} />
          <Route path={'/services/uiux'} element={<UIUXPage />} />
          <Route path={'/services/maintenance'} element={<MaintenancePage />} />
          <Route path={'/contact'} element={<ContactPage />} />
        </Routes>

        {/* <Route path={'/about'} exact component={AboutPage} />
        <Route path={'/reach'} exact component={ContactPage} /> */}
      </Router>
    )
  }
}

export default App;