import React from 'react';
import './style.css';

class Service extends React.Component {
  doRenderText() {
    let titleText = "Services";
    let subtitleText = "that we provided";
    return (
      <div className='text'>
        <h1>{titleText}</h1>
        <p>{subtitleText}</p>
      </div>
    );
  }
  doRenderCards() {
    return (
      <div className='service-container'>
        <div className='service-card'>
          <div className='pengembangan-web'></div>
          <div className='item-text'>
            <p className='title'>Web Development</p>
            <p className='subtitle'>(HTML, JS, CSS)</p>
          </div>
        </div>
        <div className='service-card'>
          <div className='pengembangan-aplikasi'></div>
          <div className='item-text'>
            <p className='title'>Mobile Apps Development</p>
            <p className='subtitle'>(Android, IOS)</p>
          </div>
        </div>
        <div className='service-card'>
          <div className='pengembangan-infrastruktur'></div>
          <div className='item-text'>
            <p className='title'>Software Infrastructure</p>
            <p className='subtitle'>(DevOps, SysAdmin)</p>
          </div>
        </div>
        <div className='service-card'>
          <div className='integrasi-software-hardware'></div>
          <div className='item-text'>
            <p className='title'>Software Hardware Integration</p>
            <p className='subtitle'>(Gate System, Printer, Scanner)</p>
          </div>
        </div>
        <div className='service-card'>
          <div className='kecerdasan-buatan'></div>
          <div className='item-text'>
            <p className='title'>Artificial Intelligence</p>
            <p className='subtitle'></p>
          </div>
        </div>
        <div className='service-card'>
          <div className='konsultasi-sistem-digital'></div>
          <div className='item-text'>
            <p className='title'>Tech Consultant</p>
            <p className='subtitle'>Planning, Diagrams</p>
          </div>
        </div>
        <div className='service-card'>
          <div className='desain-ui-ux'></div>
          <div className='item-text'>
            <p className='title'>UI/UX Designer</p>
            <p className='subtitle'>Wireframe</p>
          </div>
        </div>
        <div className='service-card'>
          <div className='it-support-penuh'></div>
          <div className='item-text'>
            <p className='title'>Dedicated IT Support</p>
            <p className='subtitle'></p>
          </div>
        </div>
      </div>
    )
  }

  render() {
    return (
      <div>
        {this.doRenderCards()}
        {this.doRenderText()}
      </div>
    );
  }
}

export default Service;